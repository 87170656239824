<template>
  <v-container>
    <h1>{{ $vuetify.lang.t('$vuetify.anmeldedaten.ueberschrift') }}</h1>
    <v-card class="my-5">
      <v-card-title>
        {{ $vuetify.lang.t('$vuetify.anmeldedaten.email_benachrichtigungen') }}
      </v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="emailNotifications"
          :label="$vuetify.lang.t('$vuetify.anmeldedaten.email_benachrichtigungen_label')"
          @change="changeEmailNotifications"
        ></v-checkbox>
      </v-card-text>
    </v-card>
    <v-card class="my-5">
      <v-form ref="emailForm" lazy-validation>
        <v-card-title>
          {{ $vuetify.lang.t('$vuetify.anmeldedaten.email_aendern') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="email"
            :rules="emailValidationRules"
            :label="$vuetify.lang.t('$vuetify.anmeldedaten.email')"
            required
            outlined
            dense
        ></v-text-field>
          <div class="text-right">
            <v-btn depressed color="primary" @click="saveEmail" :loading="emailSaving">
              {{ $vuetify.lang.t('$vuetify.speichern') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-form>
    </v-card>
    <v-card>
      <v-form ref="passwordForm" lazy-validation>
        <v-card-title>
          {{ $vuetify.lang.t('$vuetify.anmeldedaten.passwort_aendern') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  :label="$vuetify.lang.t('$vuetify.anmeldedaten.neues_passwort')"
                  type="password"
                  required
                  outlined
                  dense
              ></v-text-field>
              <PasswordCheck :password="password"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="passwordRepeat"
                  :rules="passwordRepeatRules"
                  :label="$vuetify.lang.t('$vuetify.anmeldedaten.passwort_wiederholen')"
                  type="password"
                  required
                  outlined
                  dense
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="text-right">
            <v-btn depressed color="primary" @click="savePassword" :loading="passwordSaving">
              {{ $vuetify.lang.t('$vuetify.speichern') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-form>
    </v-card>
    <v-card class="mt-5" v-if="webauthnEnabled">
      <v-card-title>
        {{ $vuetify.lang.t('$vuetify.anmeldedaten.public_key') }}
      </v-card-title>
      <v-card-text>
        <div v-if="saveCredentials">
          <p>
            {{ $vuetify.lang.t('$vuetify.anmeldedaten.public_key_entfernen_hinweis') }}
          </p>
          <div class="text-right">
            <v-btn depressed color="primary" @click="removePublicKeyDevice" class="mr-4" :block="$vuetify.breakpoint.xs">
              {{ $vuetify.lang.t('$vuetify.anmeldedaten.entfernen_button') }}
            </v-btn>
            <br v-if="$vuetify.breakpoint.xs"/>
            <v-btn depressed color="primary" @click="removePublicKey" :loading="credentialsLoading" :block="$vuetify.breakpoint.xs">
              {{ $vuetify.lang.t('$vuetify.anmeldedaten.entfernen_alle_button') }}
            </v-btn>
          </div>
        </div>
        <div v-else>
          <p>
            {{ $vuetify.lang.t('$vuetify.anmeldedaten.public_key_anlegen_hinweis') }}
          </p>
          <div class="text-right">
            <v-btn depressed color="primary" @click="addPublicKey" :loading="credentialsLoading">
              {{ $vuetify.lang.t('$vuetify.anmeldedaten.anlegen_button') }}
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import client from '@/utils/client';
import Webauthn from '@/utils/webauthn';
import PasswordCheck from '@/components/PasswordCheck';

export default {
  components: {PasswordCheck},
  data: () => ({
    emailNotifications: true,
    emailValidationRules: [],
    password: '',
    passwordRepeat: '',
    passwordRules: [],
    passwordRepeatRules: [],
    emailSaving: false,
    passwordSaving: false,
    saveCredentials: false,
    credentialsLoading: false,
    webauthnEnabled: process.env.VUE_APP_WEBAUTHN === '1',
  }),
  computed: {
    email: {
      get() {
        return this.$store.state.user.email;
      },
      set(value) {
        this.$store.commit('user/load', {email:value});
      }
    },
  },
  mounted() {
    this.emailValidationRules = [
      v => !!v || this.$vuetify.lang.t('$vuetify.login.email_erforderlich'),
      v => /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(v) || this.$vuetify.lang.t('$vuetify.login.email_ungueltig')
    ];
    this.passwordRules = [
      v => !!v || this.$vuetify.lang.t('$vuetify.optIn.passwort_erforderlich'),
      v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!^°§$%&/()|[\]=?+*#\-_.:,;<>€@{ }'ß])[A-Za-z\d!^°§$%&/()|[\]=?+*#\-_.:,;<>€@{ }'ß]{8,}$/.test(v) || this.$vuetify.lang.t('$vuetify.optIn.passwort_ungueltig')
    ];
    this.passwordRepeatRules = [
      v => !!v || this.$vuetify.lang.t('$vuetify.optIn.passwort_wiederholen_erforderlich'),
      v => v === this.password || this.$vuetify.lang.t('$vuetify.optIn.passwort_unterschied'),
    ];
    this.saveCredentials = localStorage.getItem('credentialId') && localStorage.getItem('credentialId').length > 1;
    this.emailNotifications = this.$store.state.user.notify;
  },
  methods: {
    saveEmail() {
      if(this.$refs['emailForm'].validate()) {
        this.emailSaving = true;
        client.put('/mieter/email', {email: this.email}).then(() => {
          this.emailSaving = false;
          this.$store.commit('snackbar/set', {show: true, color: 'success', message: this.$vuetify.lang.t('$vuetify.anmeldedaten.email_gespeichert')});
        }).catch(() => {
          this.emailSaving = false;
        });
      }
    },
    savePassword() {
      if(this.$refs['passwordForm'].validate()) {
        this.passwordSaving = true;
        client.put('/mieter/passwort', {passwort: this.password}).then(() => {
          this.passwordSaving = false;
          this.$store.commit('snackbar/set', {show: true, color: 'success', message: this.$vuetify.lang.t('$vuetify.anmeldedaten.passwort_gespeichert')});
        }).catch(() => {
          this.passwordSaving = false;
        });
      }
    },
    addPublicKey() {
      this.credentialsLoading = true;
      Webauthn.createPublicKey(this.$store.state.user).then(data => {
        let payload = {
          clientDataJSON: data.response.clientDataJSON  ? Webauthn.arrayBufferToBase64(data.response.clientDataJSON) : null,
          attestationObject: data.response.attestationObject ? Webauthn.arrayBufferToBase64(data.response.attestationObject) : null,
          challenge: Webauthn.arrayBufferToBase64(Webauthn.challenge),
        };
        client.post('/mieter/credential', payload).then(() => {
          localStorage.setItem('credentialId', Webauthn.arrayBufferToBase64(data.rawId));
          this.$store.commit('user/load', {credential: true});
          this.saveCredentials = true;
          this.$store.commit('snackbar/set', {show: true, color: 'success', message: this.$vuetify.lang.t('$vuetify.login.id_gespeichert')});
          this.credentialsLoading = false;
        }).catch(() => {
          this.credentialsLoading = false;
        });
      }).catch(() => {
        this.$store.commit('snackbar/set', {show: true, color: 'error', message: this.$vuetify.lang.t('$vuetify.unbekannter_fehler')});
        this.credentialsLoading = false;
      });
    },
    removePublicKey() {
      this.credentialsLoading = true;
      client.delete('/mieter/credential').then(() => {
        localStorage.removeItem('credentialId');
        this.$store.commit('snackbar/set', {show: true, color: 'success', message: this.$vuetify.lang.t('$vuetify.login.id_entfernt')});
        this.saveCredentials = false;
        this.credentialsLoading = false;
      });
    },
    removePublicKeyDevice() {
      localStorage.removeItem('credentialId');
      this.$store.commit('snackbar/set', {show: true, color: 'success', message: this.$vuetify.lang.t('$vuetify.login.id_entfernt')});
      this.saveCredentials = false;
    },
    changeEmailNotifications() {
      client.put('/mieter/' + this.$store.state.user.id, {notify: this.emailNotifications}).then(() => {
        this.$store.commit('snackbar/set', {show: true, color: 'success', message: this.$vuetify.lang.t('$vuetify.anmeldedaten.email_benachrichtigungen_aktualisiert')});
        this.$store.commit('user/load', {notify: this.emailNotifications});
      }).catch(() => {});
    }
  }
}
</script>
